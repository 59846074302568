import type { GetMenusCommandOutput } from "@internal/server-api";
import type { Board } from "~~/../../packages/database/dist";

import { GetMenusCommand } from "@internal/server-api";

import { useAccountStore } from "./account";
export const useBoardStore = defineStore("board", {
  actions: {
    isReadable(board: Board): boolean {
      const accountStore = useAccountStore();
      return board.loginRequired ? accountStore.loggedIn : true;
    },
    isWritable(board: Board): boolean {
      const accountStore = useAccountStore();
      return board.userReadonly
        ? accountStore.me.role === "Admin" || accountStore.me.role === "Manager"
        : accountStore.loggedIn;
    },
    async refresh() {
      this.menus = (await useAPI().send(new GetMenusCommand())).menus;
    },
    resetLastVisitedPage() {
      this.lastVisitedPage.id = null;
      this.lastVisitedPage.page = 1;
    },
    setLastVisitedPage(id: string, page: number) {
      this.lastVisitedPage.id = id;
      this.lastVisitedPage.page = page;
    },
  },
  getters: {
    getBoardById(state) {
      return (id: string) => {
        for (const menu of state.menus) {
          const board = menu.boards.find((menu) => menu.id === id);
          if (board) {
            return board;
          }
        }
        return undefined;
      };
    },
  },
  state: () => ({
    boardDetails: new Map<string, Board>(),
    lastVisitedPage: {} as { id?: string; page: number },
    menus: [] as GetMenusCommandOutput["menus"],
  }),
});
