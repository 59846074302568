import type { Board } from "@internal/database";
import type { Axios, AxiosResponse } from "axios";

import type { Command } from "../type";
export interface GetMenusCommandOutput {
  menus: ({ title: string } & { boards: Pick<Board, "id" | "title">[] })[];
}
export class GetMenusCommand implements Command<GetMenusCommandOutput> {
  async execute(httpClient: Axios): Promise<GetMenusCommandOutput> {
    const response: AxiosResponse = await httpClient.get("/menu");
    const result: GetMenusCommandOutput = { menus: response.data };
    return result;
  }
}
